<template>
  <base-section
    id="devices"
    space="56"
    class="primary"
  >
    <base-section-heading title="Backoffice on the fly">
    </base-section-heading>
    <div style="padding-bottom: 16px; max-width: 840px; text-align: center; margin: auto; font-weight: 800; font-size: 1rem;">
      Designed for your grandma. Suitable for teams of any size and is ideal to accept orders | bookings 24/7, generate tasks and keep your customers happy. With tentifly you can sell your products, bundles, services and even rent!
    </div>
    <div class="perksContainer">
      
    </div>
  </base-section>
</template>

<script>
export default {
  name: "Devices",
  methods: {
  },
  data: () => ({
  })
};
</script>
